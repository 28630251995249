export function generateNavigation(currentPage, companyName) {
  const salesNavigation = [
    { text: "PRUworks" },
    { text: "Dashboard", link: "/auth/dashboard" },
    { text: "Sales Portal" }
  ];

  const policyNavigation = [{ text: "PRUworks" }, { text: "Client & Policy Information", link: "/auth/pi" }];

  // Add companyName's Dashboard
  const companyNameDashboard = companyName ? `${companyName}'s Dashboard` : "Dashboard";
  if (currentPage !== "PolicyInformation") {
    policyNavigation.push({ text: companyNameDashboard, link: "/auth/pi/dashboard" });
  }

  switch (currentPage) {
    //for Sales Portal
    case "Information":
      return [...salesNavigation, { text: "Information" }];
    case "CurrentQuote":
      return [...salesNavigation, { text: "Current Quote" }];
    case "SavedQuotes":
      return [...salesNavigation, { text: "Saved Quotes" }];
    case "PolicyRenewal":
      return [...salesNavigation, { text: "Documents" }];

    //for Policy Listing
    case "PolicyInformation":
      return [...policyNavigation];

    //for Policy Portal
    case "PolicyDashboard":
      return [...policyNavigation];
    case "MyEmployees":
      return [...policyNavigation, { text: "Member Listing" }];
    case "InsuranceCoverage":
      return [...policyNavigation, { text: "Insurance Coverage" }];
    case "claimExpenses":
      return [...policyNavigation, { text: "Claim Expenses" }];
    case "PolicyDocuments":
      return [...policyNavigation, { text: "Documents" }];
    default:
      return [];
  }
}
