import React from "react";
import Typography from "@mui/material/Typography";
import UploadedFile from "./UploadedFile";

const RenderMultipleFileUploader = ({ title, files, Uploader, disabled }) => {
  const filesContent = files.map(file => <UploadedFile key={file.encrypted_id} file={file} disabled={disabled} />);

  return (
    <div>
      <Typography variant="subheading" gutterBottom>
        {title}
      </Typography>
      {filesContent}
      {!disabled ? Uploader : ""}
    </div>
  );
};

export default RenderMultipleFileUploader;
