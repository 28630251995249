import Enums from "../utils/Enums";

let proc = process.env;

const config = {
  baseUrl: proc.REACT_APP_API_URL,
  employeeUrl: window.MMPConfig.REACT_APP_URL_EMPLOYEE,
  app: {
    version: `version ${proc.REACT_APP_VERSION} ${proc.REACT_APP_ENV.charAt(0)}`,
    build: proc.REACT_APP_BUILD
  },
  api: {
    timeout: 300000,
    authBearer: "c2FsZXM6cGFzc3dvcmQ=", //proc.REACT_APP_API_AUTH_BEARER,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  },
  products: {
    defaultCode: "PF3",
    config: {
      PF3: {
        code: "PF3",
        version: 2
      },
      PTF: {
        code: "PTF",
        version: 1
      },
      PEP: {
        code: "PEP",
        version: 1
      }
    }
  },
  ciam: {
    publicKey:
      "DC7BF2C17EDC02E7544EE64BD189EA4E9FE92CE2A1A6A4F4A9D91A5E647F7D394066BB98C71681671972BF58FBFD5CEAD3F6F6F6488C65FD7C5624E9799C8BD532506366160B3968F11E190E130B9B77FA29A8F5949C9EB3E17345907C06CC1B05000F81CD2BC641CCBD4C4915854D9E9A3394E99FC06A11375C80C56847C677",
    randomKey: "ADBDD06F40970109482E546483BE4A0C"
  },
  fileUploadOptions: {
    // accept: ".jpg,.jpeg,.pdf",
    maxSize: 10485760
  }
};

//Check against hostname to define the app type
config.app.type = proc.REACT_APP_TYPE;
if (proc.REACT_APP_BUILD_SHARED && proc.REACT_APP_BUILD_SHARED.toLowerCase() === "true") {
  let hasExternalConfig = proc.REACT_APP_EXT_CONFIG && proc.REACT_APP_EXT_CONFIG.toLowerCase() === "true";
  let _config = hasExternalConfig ? window.MMPConfig : proc;
  // local usage
  // let _config = window.MMPConfig;
  /*let hostname = window.location.hostname;
  if (hostname === _config.REACT_APP_URL_SALES) {
    config.app.type = Enums.APP_TYPE.SALES;
  } else if (hostname === _config.REACT_APP_URL_HR) {
    config.app.type = Enums.APP_TYPE.HR;
  }*/
  config.app.type = _config.IS_HR ? Enums.APP_TYPE.HR : Enums.APP_TYPE.SALES;
  config.cut_off_date = _config.PTRF2_REVISED_BENEFITS_CUT_OFF_DATE;
  config.disable_captcha_service = _config.DISABLE_CAPTCHA_SERVICE;
  config.disable_conversion = _config.DISABLE_CONVERSION;
  config.esign_api_timeout = _config.ESIGN_API_TIMEOUT;
  config.esign_no_of_calls = _config.ESIGN_NO_OF_CALLS;
  config.esign_no_of_callbacks = _config.ESIGN_NO_OF_CALLBACKS;
  config.esign_no_of_document_status_calls = _config.ESIGN_NO_OF_DOCUMENT_STATUS_CALLS;
  if (_config.BANNER !== undefined) {
    config.bannerEnabled = _config.BANNER.BANNER_ENABLED;
    config.bannerDetails = _config.BANNER.BANNER_DETAILS;
    config.logoDisplay = _config.BANNER.LOGO_DISPLAY;
    config.bannerTitle = _config.BANNER.BANNER_TITLE;
  }
  if (_config.POPUPLOGIN !== undefined) {
    config.popUpLoginMessageEnabled = _config.POPUPLOGIN.ENABLED;
    config.popUpLoginMessage = _config.POPUPLOGIN.MESSAGE;
  }

  if (_config.APP_VERSION !== undefined) {
    config.appVersionEnabled = _config.APP_VERSION.ENABLED;
    config.appVersionNumber = _config.APP_VERSION.VERSION_NUMBER;
  }

  if (_config.MAINTENANCE !== undefined) {
    config.maintenanceEnabled = _config.MAINTENANCE.MAINTENANCE_ENABLED;
    config.maintenanceTitle = _config.MAINTENANCE.MAINTENANCE_TITLE;
    config.maintenanceSubtitle = _config.MAINTENANCE.MAINTENANCE_SUBTITLE;
    config.maintenanceDetails = _config.MAINTENANCE.MAINTENANCE_DETAILS;
    config.startDateTime = _config.MAINTENANCE.START_DATE_TIME;
    config.endDateTime = _config.MAINTENANCE.END_DATE_TIME;
  }

  if (_config.FIRST_PAGE_DIALOG !== undefined) {
    config.firstPageDialogEnabled = _config.FIRST_PAGE_DIALOG.ENABLED;
    config.firstPageDialogDetails = _config.FIRST_PAGE_DIALOG.DETAILS;
    config.dialogAutoDisable = _config.FIRST_PAGE_DIALOG.DIALOG_AUTO_DISABLED;
    config.disableBefore = _config.FIRST_PAGE_DIALOG.DISABLE_BEFORE;
  }

  if (_config.NBSTP !== undefined) {
    config.disableSubmitSignDate = _config.NBSTP.DISABLE_SUBMIT_SIGN_DATE;
    config.dateStartDisabling = _config.NBSTP.DATE_START_DISABLING;
  }
}

//Only for local development
// if(proc.REACT_APP_ENV === 'LOCAL'){
//   config.api.authBearer = proc.REACT_APP_API_AUTH_BEARER;
// }
export default config;
