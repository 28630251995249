import React from "react";
import { makeStyles } from "@mui/styles";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import BoxItemValues from "../../../assets/data/dashboardBoxItemValues";
import Util from "../../../utils/Util";
import PolicyDetailsBoxItem from "../../../components/PolicyDetailsBoxItem";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  content: {
    padding: "10px 40px"
  },
  bannerHeading: {
    fontSize: "35px",
    paddingTop: "80px",
    paddingLeft: "5px",
    marginTop: 0
  },
  bannerDesc: {
    fontSize: "20px",
    color: "#6D6D6D",
    paddingLeft: "5px"
  },
  listBoxItem: {
    width: "100%",
    margin: 0,
    display: "flex",
    justifyContent: "space-between"
  }
}));

const Homepage = ({ history }) => {
  const classes = useStyles();
  const userState = useSelector(state => state.user);

  const goToPage = id => {
    switch (id) {
      case "salesPortal":
        history.push("/auth/sp/currentQuote");
        break;
      case "clientPolicyList":
        history.push("/auth/pi");
        break;
      default:
        break;
    }
  };

  const renderBoxItemBottom = () => {
    return BoxItemValues.map((value, index) => (
      <Grid xs={12} sm={6} md={12 / BoxItemValues.length} key={index} item>
        <PolicyDetailsBoxItem data={value} onClick={() => goToPage(value.id)} />
      </Grid>
    ));
  };

  const agentName = Util.getAgentName(userState.auth.agentname, userState.auth.agentgender);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <p className={classes.bannerHeading}>
          Welcome to PRUworks, <b>{agentName}</b>
        </p>
        <p className={classes.bannerDesc}>
          Easily access your <b>Sales Portal</b> or view <b>Client Policy</b> List by clicking one of the sections below
        </p>
        <Grid className={classes.listBoxItem} container spacing={"8px"}>
          {renderBoxItemBottom()}
        </Grid>
      </div>
    </div>
  );
};

export default withRouter(Homepage);
