import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";

import { withStyles } from "@mui/styles";

import ConfirmMessageDialog from "../../../../components/ConfirmMessageDialog";
import { myEmployeeActions } from "../../../../actions";
import Util from "../../../../utils/Util";

import EmployeeEditDialog from "../../components/Quote/components/EmployeeDialogForm/EmployeeEditDialog";

const TIPS_CODE = {
  BASIC: "BASIC",
  CHANGE_CATEGORY: "CHANGE_CATEGORY",
  TERMINATE_DEPENDENT: "TERMINATE_DEPENDENT"
};

const MODE = {
  ADD: "ADD",
  EDIT: "EDIT"
};

class MyEmployeeDialog extends Component {
  constructor(props) {
    super(props);

    this.submitData = null;
    this.originalValues = null;
    this.state = {
      value: 0, // first tab : Employee Detail
      confirmSubmitDialog: false,
      tips: [],
      systemEmailExist: false
    };
  }

  componentWillMount() {
    const { selectedRecord } = this.props;
    this.setOriginalValues(selectedRecord);
    this.setTips(TIPS_CODE.BASIC);
  }

  setOriginalValues = selectedRecord => {
    if (selectedRecord) {
      this.originalValues = {
        category: selectedRecord.category
      };
    }
  };

  setTips = (code, add = true) => {
    const { intl } = this.props;

    let tips = Util.clone(this.state.tips) || [];
    let exists = this.state.tips.filter(t => t.code === code).length > 0;
    if (exists) {
      if (!add) {
        tips = tips.filter(t => t.code !== code);
      }
    } else {
      if (add) {
        if (code === TIPS_CODE.BASIC) {
          tips.push({
            code: TIPS_CODE.BASIC,
            multiTip: true,
            title: "Tips:",
            content: [
              intl.formatMessage({
                id: "hr.myemployee.tips.warning.basic"
              }),
              intl.formatMessage({
                id: "hr.myemployee.tips.warning.basic2"
              }),
              intl.formatMessage({
                id: "hr.myemployee.tips.warning.basic3"
              })
            ]
          });
        }

        if (code === TIPS_CODE.CHANGE_CATEGORY) {
          tips.push({
            code: TIPS_CODE.CHANGE_CATEGORY,
            title: "Requirement:",
            content: intl.formatMessage({
              id: "hr.myemployee.tips.warning.changeCategory"
            })
          });
        }

        if (code === TIPS_CODE.TERMINATE_DEPENDENT) {
          tips.push({
            code: TIPS_CODE.TERMINATE_DEPENDENT,
            title: "Requirement:",
            content: intl.formatMessage({
              id: "hr.myemployee.tips.warning.terminateDependent"
            })
          });
        }
      }
    }
    this.setState({ tips });
  };

  getDependentRecords = selectedRecord => {
    let dependentRecords = [];
    if (selectedRecord && !Util.isEmpty(selectedRecord["dependents"])) {
      dependentRecords = Util.clone(selectedRecord["dependents"]);
    }
    return dependentRecords;
  };

  handleEmployeeDataChange = (name, value) => {
    const { tips } = this.state;
    if (name === "category") {
      if (this.props.mode === MODE.EDIT) {
        this.setTips(TIPS_CODE.CHANGE_CATEGORY, this.originalValues.category !== value);
      }
    }
  };

  handleDependentTermination = () => {
    const { tips } = this.state;
    this.setTips(TIPS_CODE.TERMINATE_DEPENDENT);
  };

  //need to call confirm before submit
  handleSubmitCompleted = (mode, employee, dependents) => {
    //cache and call confirm
    this.submitData = {
      member: employee,
      dependents: dependents
    };
    this.handleConfirm();
  };

  handleSubmitAfterConfirmed = () => {
    this.props.showLoading(true);
    const { mode, addEmployee, updateEmployee } = this.props;
    let submitData = this.submitData;
    if (mode === MODE.ADD) {
      addEmployee(submitData).then(
        response => {
          this.setState({ systemEmailExist: false });
          this.props.showLoading(false);
          this.props.handleClose();
        },
        error => {
          this.props.showLoading(false);
          if (error.message !== "Duplicated email found") {
            this.props.handleClose();
            this.props.handleEmployeeError(error);
          } else {
            this.setState({ confirmSubmitDialog: false });
            this.setState({ systemEmailExist: true });
          }
        }
      );
    } else if (mode === MODE.EDIT) {
      updateEmployee(submitData, this.originalValues.category).then(
        response => {
          this.setState({ systemEmailExist: false });
          this.props.showLoading(false);
          this.props.handleClose();
          this.props.myEmployee.data.map(person => {
            if (person.person_id === response.data.member.id) {
              (person.bank_name = response.data.member.bank_name),
                (person.bank_account_name = response.data.member.bank_account_name),
                (person.bank_account_number = response.data.member.bank_account_number),
                (person.bank_code = response.data.member.bank_code),
                (person.branch_code = response.data.member.branch_code);
            }
          });
        },
        error => {
          this.props.showLoading(false);
          if (error.message !== "Duplicated email found") {
            this.props.handleClose();
          } else {
            this.setState({ confirmSubmitDialog: false });
            this.setState({ systemEmailExist: true });
          }
        }
      );
    }
    // this.props.handleClose();
  };

  //handle confirm dialog button
  handleConfirm = () => {
    this.setState({ confirmSubmitDialog: true });
  };

  render() {
    const { mode, open, readOnly, handleClose, selectedIndex, selectedRecord, categories } = this.props;
    const { tips } = this.state;
    return (
      <Fragment>
        <EmployeeEditDialog
          mode={mode}
          open={open}
          handleClose={handleClose}
          selectedIndex={selectedIndex}
          selectedRecord={selectedRecord}
          systemEmailExist={this.state.systemEmailExist}
          getDependentRecords={this.getDependentRecords.bind(this)}
          handleSubmitCompleted={this.handleSubmitCompleted.bind(this)}
          handleEmployeeDataChange={this.handleEmployeeDataChange.bind(this)}
          handleDependentTermination={this.handleDependentTermination.bind(this)}
          categories={categories}
          readOnly={readOnly}
          tips={tips}
        />
        <ConfirmMessageDialog
          name="confirm-employee"
          title="Submit Changes"
          description="By submitting the information of the insured members and/or their dependents to Prudential herein I hereby acknowledge and confirms that the information is true, complete and accurate, and shall form the basis of my/our contract with Prudential. I/We further acknowledge and agree that Prudential can request supporting documents in relation to the information provided above.~Further I acknowledge and confirm that I have obtained the necessary consent from the insured members and/or their dependents to collect, use, disclose and process such information to Prudential. I/We agree to indemnify Prudential against any losses, damages, costs or liabilities arising from any false, incomplete or inaccurate information, or any breach of the consent requirement."
          open={this.state.confirmSubmitDialog}
          closeHandler={() => {
            this.setState({ confirmSubmitDialog: false });
          }}
          confirmHandler={this.handleSubmitAfterConfirmed.bind(this)}
          confirmButtonText="Submit"
          closeButtonText="Cancel"
          isCheckbox={true}
          checkboxLabel="I confirm that the information provided is accurate and that I have obtained necessary authorization from the insured."
        />
      </Fragment>
    );
  }
}

const styles = theme => ({});

function mapStateToProps(state, prop) {
  return {
    myEmployee: state.HR.myemployee,
    categories: state.HR.coverage.policy.data.categories
  };
}

function mapDispatchToProps(dispatch) {
  return { ...bindActionCreators(myEmployeeActions, dispatch) };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(MyEmployeeDialog))
);
