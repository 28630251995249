import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import MyEmployeeTable from "./MyEmployeeTable";
import MyEmployeeFooter from "./MyEmployeeFooter";
import Enums from "../../../../utils/Enums";
import LoadingPopup from "../../../../components/LoadingPopup";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UploadEmployee from "./UploadEmployee";
import PolicyCard from "../../../../components/PolicyCard";
import { coverageActions, myEmployeeActions, productActions } from "../../../../actions";

class MyEmployees extends Component {
  constructor(props) {
    super(props);
    this.state = { showEmployeeTable: true };
  }

  componentWillMount() {
    const { policyNumber, companyId } = this.props.location.state;
    this.props.getMyEmployees(policyNumber, companyId, {
      filterBy: "",
      filterValue: "",
      sortBy: "full_name",
      dir: Enums.SORT.ASC
    });
  }

  componentDidMount() {
    const { policyNumber, companyId } = this.props.location.state;
    if (policyNumber) {
      this.props.getGaPolicyInfo(policyNumber);
      this.props.getPolicy(policyNumber, companyId).then(res => console.log("res.data", res.data));
    }
  }

  onCellClickHandler = id => () => {
    this.toolbar.onCellClickHandler(id);
  };

  handleAddEmployee = () => {
    this.setState({ showEmployeeTable: !this.state.showEmployeeTable });
  };

  render() {
    const { productCode } = this.props.location.state;
    const { classes, myEmployee, navDrawerOpen, coverage } = this.props;
    const isPCEClient = productCode === "GCP" || productCode === "PCE";
    const isPTFClient = productCode === "PF3" || productCode === "PTF" || productCode === "PEP";

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          {this.state.showEmployeeTable && (
            <div>
              <Typography variant="h5" fontWeight={700} mb={2}>
                {this.props.location.state.company}'s Employees
              </Typography>
              <PolicyCard gaInfo={coverage.gaInfo} />
              <br />
              <div className={classes.headerTools}>
                <Typography variant="h5" color="inherit">
                  My Employees
                </Typography>
                {(productCode === "GCP" || productCode === "PCE") && (
                  <Button size="small" onClick={this.handleAddEmployee}>
                    Add Employees <AddIcon />
                  </Button>
                )}
              </div>

              {coverage.policy && (
                <MyEmployeeTable
                  data={myEmployee.data}
                  selectedIds={myEmployee.selectedIds}
                  cellClickHandler={this.onCellClickHandler}
                  isPCEClient={isPCEClient}
                  isPTFClient={isPTFClient}
                />
              )}
            </div>
          )}
          {!this.state.showEmployeeTable && (
            <UploadEmployee showEmployeeTable={this.handleAddEmployee} navDrawerOpen={navDrawerOpen} />
          )}
        </div>

        {isPTFClient && this.state.showEmployeeTable ? (
          <MyEmployeeFooter
            navDrawerOpen={navDrawerOpen}
            selectedIds={myEmployee.selectedIds}
            onRef={ref => (this.toolbar = ref)}
          />
        ) : (
          <LoadingPopup name="hr-submit-claim-on-behalf-loading" open={myEmployee.showLoading} />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%",
    paddingTop: "40px",
    paddingLeft: "40px",
    paddingRight: "40px"
  },
  content: {
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10,
    marginBottom: 65
  },
  headerTools: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px"
  }
});

function mapStateToProps(state) {
  return {
    myEmployee: state.HR.myemployee,
    loggedUser: state.user,
    coverage: state.HR.coverage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(myEmployeeActions, dispatch),
    ...bindActionCreators(productActions, dispatch),
    ...bindActionCreators(coverageActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(MyEmployees)));
