import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import classNames from "classnames";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { employeeActions, validationsActions } from "../../../../../../actions";
import TableToolBarHeader from "./TableToolBarHeader";
import { injectIntl } from "react-intl";
import Util from "../../../../../../utils/Util";
import Enums from "../../../../../../utils/Enums";
import ErrorSummary from "../../../../../../components/ErrorSummary";
import countries from "../../../../../../assets/data/countries";
import maritalStatusValues from "../../../../../../assets/data/maritalValues";
import genderValues from "../../../../../../assets/data/genderValues";

class EmployeeTable extends Component {
  constructor(props) {
    super(props);

    this.type = Enums.PERSON_TYPE.EMPLOYEE;
    this.handlers = {
      validateEmployeeData: Util.debounce(200, this.validateEmployeeData)
    };
    this.state = {
      errorsEmail: []
    };
  }

  isAllSelected = () => {
    return (
      this.props.employee.selectedIds.length === this.props.quote.data.persons.filter(p => p.type === this.type).length
    );
  };

  isSelected = id => this.props.employee.selectedIds.indexOf(id) !== -1;

  handleSelectAllClick = (event, checked) => {
    let selectedIds = [];
    if (checked) {
      this.props.employeeData.forEach((n, idx) => {
        if (n.type === this.type) selectedIds.push(idx);
      });
    }
    this.props.setSelectedEmployees(selectedIds);
  };

  handleCheck = (event, id, person) => {
    const { selectedIds } = this.props.employee;

    var toAddCheck = !selectedIds.includes(id);
    var newSelected = selectedIds;
    if (toAddCheck) {
      newSelected.push(id);
    } else {
      newSelected = selectedIds.filter(s => s !== id);
    }
    //Make sure the list is unqiue
    newSelected = Util.uniqueList(newSelected);
    this.props.setSelectedEmployees(newSelected);
  };

  componentWillMount() {
    this.handlers.validateEmployeeData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.handlers.validateEmployeeData(nextProps);
    this.removeError(nextProps);
  }

  componentDidMount() {
    const { quote } = this.props;
    const emails = quote.data.persons.map(person => person.email).filter(email => email !== "");
    this.props.emailValidations(emails);
  }

  removeError(props) {
    const { emailErrors } = props;
    const { email_results } = emailErrors || {};
    let isNoEmailUsedErrorExist = true;
    if (Object.keys(email_results || {}).length) {
      for (const [_key, val] of Object.entries(email_results)) {
        if (
          val.includes(
            "The email address you have entered is already in used in our system. Please provide a different email address."
          )
        ) {
          if (this.state.errorsEmail.length) {
            this.setState({
              errorsEmail: []
            });
          }
          isNoEmailUsedErrorExist = false;
        }
      }
    }

    if (isNoEmailUsedErrorExist && this.state.errorsEmail.length) {
      this.setState({
        errorsEmail: []
      });
    }
  }

  validateEmployeeData = props => {
    let persons = props.quote.data.persons;
    if (!props.quote.isResetting && persons.length > 0) {
      if (persons[0].isValid === undefined) {
        props.validateEmployeeData();
      }
    }
  };

  isInvalidCell = (row, checkCols = []) => {
    if (!row.invalid_fields) return false;
    return checkCols.filter(c => row.invalid_fields.includes(c)).length > 0;
  };

  checkEmailIsInvalid(email) {
    const { emailErrors, intl } = this.props;
    const { email_results } = emailErrors || {};

    if (!Util.isEmail(email)) return true;

    if (Util.isEmailForbiddenDomainPatterns(email)) return true;

    if (Util.isEmailForbiddenUsernamePatterns(email)) return true;

    const isEmailExist = this.props.quote.data.persons.filter(person => person.email === email).length > 1;
    if (isEmailExist) return true;

    if (Object.keys(email_results || {}).length && email_results[email]?.length) {
      if (
        email_results[email].includes(
          "The email address you have entered is already in used in our system. Please provide a different email address."
        )
      ) {
        if (!this.state.errorsEmail.length) {
          this.setState({
            errorsEmail: [
              {
                title: "Invalid email",
                error: intl.formatMessage({ id: "error.msg.employee.invalidEmailExist" })
              }
            ]
          });
        }
      }
      return true;
    }
    return false;
  }

  renderCell(isValidRow, isInValidCell, cellData, addInfo = "", condition) {
    let isEmailInvalid = condition === "Email" && this.checkEmailIsInvalid(cellData);
    const { classes, intl } = this.props;
    if (!isInValidCell) {
      let isDataEmpty = Util.isEmpty(cellData);
      return (
        <TableCell
          className={classNames(
            isValidRow === false && classes.errorRow,
            (isDataEmpty || isEmailInvalid) && classes.missingCell
          )}
        >
          {isDataEmpty ? intl.formatMessage({ id: "error.missing" }) : addInfo + cellData}
        </TableCell>
      );
    } else if (Util.isEmpty(cellData)) {
      return (
        <TableCell className={classNames(isValidRow === false && classes.errorRow, classes.missingCell)}>
          {intl.formatMessage({ id: "error.missing" })}
        </TableCell>
      );
    } else {
      return (
        <TableCell className={classNames(isValidRow === false && classes.errorRow, classes.missingCell)}>
          {addInfo + cellData}
        </TableCell>
      );
    }
  }

  renderBankCell(isValidRow, isInValidCell, cellData, addInfo = "", condition) {
    const { classes, intl } = this.props;
    return (
      <TableCell>
        {addInfo + cellData}
      </TableCell>
    );
  }

  countEmployees = employees => {
    return employees.reduce((a, c) => (c.type === this.type ? 1 : 0) + a, 0);
  };

  getValuesLabel = (val, values) => {
    let obj = null;
    if (val) {
      obj = values.find(c => c.value === val);
    }
    return val && obj ? obj.label : null;
  };

  getOccupationClass = val => {
    let value = null;
    if (val && Util.contains(val, "F")) {
      value = val.slice(1);
    }
    return value;
  };

  render() {
    const { classes, quote, errors, document } = this.props;
    let persons = quote.data.persons;

    let readOnly = this.props.quote.readOnly || document.hasSignedProposal;
    return (
      <div>
        <TableToolBarHeader employeeLength={this.countEmployees(persons)} />

        {this.state.errorsEmail.length ? (
          <ErrorSummary
            errors={[...errors, ...this.state.errorsEmail]}
            title="Premium will be calculated when the following is/are resolved"
          />
        ) : (
          <ErrorSummary errors={errors} title="Premium will be calculated when the following is/are resolved" />
        )}

        {/*Employee Listing*/}
        <Paper className={classes.root}>
          <Table className={classNames({ hidden: persons.length === 0 }, classes.table)}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    disabled={readOnly}
                    color="primary"
                    checked={this.isAllSelected()}
                    onChange={this.handleSelectAllClick}
                  />
                </TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>NRIC</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Dependants</TableCell>
                <TableCell>Occupation Class</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>Date Of Birth</TableCell>
                <TableCell>Company Email</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Marital Status</TableCell>
                <TableCell>Nationality</TableCell>
                <TableCell>Residence</TableCell>
                <TableCell>Foreign Worker (S-pass/Work Permit)</TableCell>
                <TableCell>Bank Account Name</TableCell>
                <TableCell>Bank Account No.</TableCell>
                <TableCell>Bank Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {persons.map((n, id) => {
                if (n.type !== this.type) return null;
                let maritalStatus = this.getValuesLabel(n.marital_status, maritalStatusValues);
                let country = this.getValuesLabel(n.nationality, countries);
                let gender = this.getValuesLabel(n.gender, genderValues);
                let occupation_class = this.getOccupationClass(n.occupation_class);

                document.hasSignedProposal;

                let validRow = document.hasSignedProposal ? true : n.isValid;

                return (
                  <TableRow key={id}>
                    <TableCell padding="checkbox" className={classNames(validRow === false && classes.errorRow)}>
                      <Checkbox
                        color="primary"
                        checked={this.isSelected(id)}
                        onChange={event => this.handleCheck(event, id, n)}
                      />
                    </TableCell>
                    {this.renderCell(validRow, this.isInvalidCell(n, ["full_name"]), n.full_name)}
                    {this.renderCell(validRow, this.isInvalidCell(n, ["id_no"]), n.id_no)}
                    {this.renderCell(validRow, this.isInvalidCell(n, ["category"]), n.category)}
                    {this.renderCell(
                      validRow,
                      this.isInvalidCell(n, ["no_of_dependent", "dependent_error"]),
                      n.no_of_dependent
                    )}
                    {this.renderCell(validRow, this.isInvalidCell(n, ["occupation_class"]), occupation_class, "Class ")}
                    {this.renderCell(validRow, this.isInvalidCell(n, ["date_of_employment"]), n.date_of_employment)}
                    {this.renderCell(validRow, this.isInvalidCell(n, ["dob", "age"]), n.dob)}
                    {this.renderCell(validRow, this.isInvalidCell(n, ["email"]), n.email, "", "Email")}
                    {this.renderCell(validRow, this.isInvalidCell(n, ["gender"]), gender)}
                    {this.renderCell(validRow, this.isInvalidCell(n, ["marital_status"]), maritalStatus)}
                    {this.renderCell(validRow, this.isInvalidCell(n, ["nationality"]), country)}
                    {this.renderCell(validRow, this.isInvalidCell(n, ["country_of_residence"]), n.country_of_residence)}
                    {this.renderCell(
                      validRow,
                      this.isInvalidCell(n, ["foreign_worker"]),
                      n.foreign_worker === true ? "Y" : n.foreign_worker === false ? "N" : n.foreign_worker
                    )}
                    {this.renderBankCell(!Util.isEmpty(n.bank_account_name), this.isInvalidCell(n, ["bank_account_name"]), n.bank_account_name)}
                    {this.renderBankCell(
                      !Util.isEmpty(n.bank_account_number),
                      this.isInvalidCell(n, ["bank_account_number"]),
                      n.bank_account_number
                    )}
                    {this.renderBankCell(!Util.isEmpty(n.bank_name), this.isInvalidCell(n, ["bank_name"]), n.bank_name)}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto"
  },
  tableToolbarWrapper: {
    display: "flex"
  },
  button: {
    marginTop: theme.spacing.unit * 2
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  table: {
    minWidth: 700
  },
  errorRow: {
    backgroundColor: "rgba(255,0,0,0.1)"
  },
  missingCell: {
    color: theme.palette.primary.main
  },
  dialogTitleWrapper: {
    textAlign: "center"
  },
  dialogContentWrapper: {
    textAlign: "center",
    color: "black"
  },
  centerLayout: {
    display: "flex",
    justifyContent: "center"
  }
});

function mapStateToProps(state, prop) {
  return {
    employee: state.quote.employee,
    quote: state.quote,
    document: state.document,
    emailErrors: state.validations.emailErrors
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...employeeActions, ...validationsActions }, dispatch);
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EmployeeTable))
);
