import * as actionTypes from "./types";

import documentService from "../services/document.service";

export const documentActions = {
  uploadFile,
  deleteFile,
  getFiles,
  resetFiles
};

function deleteFile(quoteId, fileId) {
  return dispatch => {
    return dispatch({
      type: actionTypes.DOCUMENT_DELETE,
      payload: documentService.deleteFile(quoteId, fileId)
    }).then(() => dispatch(getFiles(quoteId)));
  };
}

function uploadFile(quoteId, actionType, fileType, file) {
  return dispatch => {
    return dispatch({
      type: actionType,
      payload: documentService.upload(quoteId, fileType, file)
    }).then(
      resp => {
        return dispatch(getFiles(quoteId));
      },
      error => {
        dispatch({ type: `${actionType}_REJECTED`, payload: error });
        return Promise.reject(error);
      }
    );
  };
}

function getFiles(quoteId) {
  return {
    type: actionTypes.DOCUMENT_ALL_GET,
    payload: documentService.getAllFilesByQuoteId(quoteId)
  };
}

function resetFiles() {
  return {
    type: actionTypes.DOCUMENT_RESET
  };
}
