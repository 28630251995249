import { APIResource, APIError, APIResponse } from "../utils/APIResource";

export default class DocumentService {
  static upload(quoteId, fileType, file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("document_type", fileType);
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    return APIResource.post(`/v1/quote/${quoteId}/document`, formData, config)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        error.response.data.message =
          "Application has run into an exception while parsing the uploaded file, please try again.";
        return Promise.reject(new APIError(error));
      });
  }

  static getAllFilesByQuoteId(quoteId) {
    return APIResource.get(`/v1/quote/${quoteId}/document`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static deleteFile(quoteId, fileId) {
    return APIResource.delete(`/v1/quote/document/${fileId}`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getDocumentByName(documentName) {
    return APIResource.get(`/v1/document/${documentName}`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
