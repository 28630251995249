import React from "react";
import Typography from "@mui/material/Typography";
import UploadedFile from "./UploadedFile";

const RenderFileOrUploader = ({ title, files, Uploader, disabled }) => {
  if (files.length > 0) {
    const filesContent = files.map(file => <UploadedFile key={file.encrypted_id} file={file} disabled={disabled} />);

    return (
      <div>
        <Typography variant="subheading" gutterBottom>
          {title}
        </Typography>
        {filesContent}
      </div>
    );
  } else {
    return Uploader;
  }
};

export default RenderFileOrUploader;
