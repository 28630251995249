import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { appActions } from "../../../actions";
import DOMPurify from "dompurify";

class FirstPageDialog extends Component {
  constructor(props) {
    super(props);
  }

  closeDialog = () => {
    this.props.setFirstPageDialogVisibility(false);
  };

  renderDetails = () => {
    if (!this.props.message) return null;

    const { classes } = this.props;
    return this.props.message.map((item, idx) => (
      <span 
        key={idx}
        classes={{ root: classes.typography }}
        style={{color: "red"}}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(item)
        }}
      />
    ));
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.app.showFirstPageDialog}
        onClose={this.closeDialog}
        disableBackdropClick
        maxWidth={false}
        classes={{
          paper: classes.dialogPaper
        }}
      >
        <DialogContent>
          <>{this.renderDetails()}</>

          <Button onClick={this.closeDialog} variant="fab" className={classes.closeButtonFab}>
            <i className="material-icons">close</i>
          </Button>

          <DialogActions
            classes={{
              root: classes.dialogActions
            }}
          >
            <Button onClick={this.closeDialog} className={classes.closeButton} variant="raised" color="primary">
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialogPaper: {
    maxWidth: 700
  },
  typography: {
    fontSize: "1rem"
  },
  closeButton: {
    minWidth: 130
  },
  dialogContent: {
    padding: "1rem"
  },
  closeButtonFab: {
    position: "fixed",
    top: 20,
    right: 20,
    [theme.breakpoints.down("sm")]: {
      opacity: 0.3,
      "&:hover": {
        opacity: 1
      }
    }
  },
  dialogActions: {
    justifyContent: "center"
  }
});

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(appActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(FirstPageDialog));
